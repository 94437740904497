import { groq } from 'next-sanity'
import { getClient } from './sanity'

export const textQuery = groq`
  ...,
  markDefs[] {
    ...,
    _type == 'definition' => {
      _key,
      _type,
      "title": definitionRef->title,
      "content": definitionRef->text,
    },
    _type == 'internalLink' => {
      _key,
      _type,
      "target": {
        "slug": @.reference->slug.current,
        "_type": @.reference->_type,
      },
    },
  },
`

export const IMAGE_FIELDS = groq`{
  ...,
  "dimensions": asset->metadata.dimensions,
  "lqip": asset->metadata.lqip,
  "palette": asset->metadata.palette,
}`

export const topicThemeQuery = groq`{
  _id,
  "light": colors.light,
  "background": colors.background,
  "promoted": colors.promoted,
  "dark": colors.dark,
  "illustration": {
    "aspect": illustration.png.asset->metadata.dimensions.aspectRatio,
    "path": illustration.png.asset->url,
    "webp": illustration.webp.asset->url
  }
}`

const collaboratorsQuery = groq`
  collaborators[]{
    _key,
    ...@->{
      title,
      href
    }
  }
`

export const resourceQuery = groq`{
  title,
  'slug': slug.current,
  preamble,
  typeTag,
  image {
    alt,
    'dimensions': asset->metadata.dimensions,
    'url': asset->url
  },
  file {
    ...,
    asset-> {
      ...
    }
  },
  'lessonRef': *[_type == 'lesson' && references(^._id)] {
    topic->{
      _type,
      title,
      'illustration': illustration.webp.asset->
    },
    title,
    _id,
    'slug': slug.current,
    types
  }
}`

export const staticPageQuery = groq`{
  ...,
  "topicTheme": topic->${topicThemeQuery},
  image ${IMAGE_FIELDS},
  video {
    ...,
    "mp4": mp4.asset->{
      mimeType,
      url,
    },
    "webm": webm.asset->{
      mimeType,
      url,
    },
  },
  sections[]{
    ...,
    cards[]{
      _key,
      _type,
      _type == "link" => {
        ...,
        image ${IMAGE_FIELDS},
      },
      _type == "image" => {
        assets[]{
          ...,
          _type == 'internalResource' => {
            'entry': entry->{
              _type,
              title,
              'slug': slug.current,
            }
          },
          _type == 'file' => {
            asset->{
              size,
              extension,
              mimeType,
              url,
            }
          },
          _type == 'resource' => {
            "title": resource->title,
            "asset": resource->file.asset->{
              size,
              extension,
              mimeType,
              url
            }
          }
        },
        facsimile,
        image ${IMAGE_FIELDS},

      },
      _type == "sectionCard" => {
        ...,
        text[] {
          ${textQuery}
        },
        extra[] {
          ...,
          blocks[] {
            ...,
            children,
            markDefs[] {
              ...,
              'internalLink': {
                "_type": reference->_type,
                "slug": reference->slug.current
              }
            },
          }
        },
        media[]{
          ...,
          _type == 'image' => ${IMAGE_FIELDS},
        },
        assets[]{
          ...,
          _type == 'internalResource' => {
            'entry': entry->{
              _type,
              title,
              'slug': slug.current,
            }
          },
          _type == 'file' => {
            asset->{
              size,
              extension,
              mimeType,
              url,
            }
          },
          _type == 'resource' => {
            "title": resource->title,
            "asset": resource->file.asset->{
              size,
              extension,
              mimeType,
              url
            }
          }
        }
      },
      ...@->{
        _type,
        _type == "video" => {
          id,
          title,
          caption
        },
        _type == "talkingType" => {
          _id,
          title,
          language,
          talkingCardTranslations->{...},
          "slug": slug.current,
          "topicTheme": topic->${topicThemeQuery}
        },
        _type == 'quiz' => {
          title,
          'slug': slug.current,
        },
        _type == 'presentation' => {
          title,
          'slug': slug.current,
        },
        _type == 'resource' => {
          title,
          'slug': slug.current,
          'fileExtension': file.asset->extension,
          },

        _type == "lesson" => {
          _id,
          title,
          purpose,
          duration,
          types,
          topic->{
            title,
            "slug": slug.current
          },
          "topicTheme": topic->${topicThemeQuery},
          "slug": slug.current,
        },
        _type == "article" => {
          _id,
          "slug": slug.current,
          title,
          image${IMAGE_FIELDS},
          preamble,
          publishedAt,
          date,
          source,
          level
        }
      }
    }
  },
  "collaborators": ${collaboratorsQuery}
}`

export const lessonQuery = (limited = false) => groq`{
  _id,
  _type,
  publishedAt,
  _updatedAt,
  title,
  purpose,
  preamble,
  duration,
  ogImages,
  types,
  'subjects':
      *[_type == "subject" && _id in array::unique(^.competencyGoals[]->competencyGoalSets[]->plan->{
        "subject": *[_type == "subject" && references(^._id)]
      }.subject[]._id)][] {
        title,
        'value': slug.current,
        'grade': {
          'vgs':  count(^.competencyGoals[]->competencyGoalSets[]->usedInGrades[][@ in ['vg1', 'vg2', 'vg3', 'paabygning_generell_studiekompetanse']]) > 0,
          'grunnskole': count(^.competencyGoals[]->competencyGoalSets[]->usedInGrades[][@ in ['aarstrinn8', 'aarstrinn9', 'aarstrinn10']]) > 0,
        }
      },
  topic->{
    title,
    "slug": slug.current,
    ${
      limited !== true
        ? `
    "lessons": *[
      _type=='lesson' &&
      references(^._id) &&
      type != 'intro' &&
      (!defined(types.intro) || types.intro == false)
    ] {
      _id,
      types,
      title,
      duration,
      'slug': slug.current,
      "topicTheme": ^->${topicThemeQuery}
    },`
        : ''
    }
  },
  "slug": slug.current,
  ${
    limited !== true
      ? `sections[] {
    "_key": sections[0]._key,
    "title": sections[0].title,
    sections[] {
      ...,
      text[] {
        ${textQuery}
      },
      extra[] {
        ...,
        blocks[] {
          ...,
          children,
          markDefs[] {
            ...,
            'internalLink': {
              "_type": reference->_type,
              "slug": reference->slug.current
            }
          },
        }
      },
      media[]{
        ...,
        _type == 'image' => ${IMAGE_FIELDS},
        _type == 'muxVideo' => {
          video{..., asset->}
        }
      },
      assets[]{
        ...,
        _type == 'internalResource' => {
          'entry': entry->{
            _type,
            title,
            'slug': slug.current,
          }
        },
        _type == 'file' => {
          asset->{
            size,
            extension,
            mimeType,
            url,
          }
        },
        _type == 'resource' => {
          "title": resource->title,
          "asset": resource->file.asset->{
            size,
            extension,
            mimeType,
            url
          }
        }
      }
    }
  },`
      : ''
  }
  competencyGoals[]->{
    _id,
    title,
    "planTitle": plans[0]->{
      "title": coalesce(shortTitle, title)
    },
    "interdisciplinarySkills": interdisciplinarySkills[]->{
      code,
      title
    },
    "coreElements": coreElements[]->title,
    "competencyGoalSetTitle": competencyGoalSets[0]->shortTitle
  },
  "topicTheme": topic->${topicThemeQuery},
  "collaborators": ${collaboratorsQuery}
}`

export const topicQuery = groq`{
  _id,
  title,
  preamble,
  "slug": slug.current,
  ogImages,
  promotedLesson->{
    _id,
    title,
    purpose,
    duration,
    'slug': slug.current
  },
  "lessonOrder": lessonOrder[]._ref,
  "lessons": *[
    _type=='lesson' &&
    references(^._id) &&
    type != 'intro' &&
    (!defined(types.intro) || types.intro == false)
  ] {
    _id,
    title,
    purpose,
    duration,
    types,
    'slug': slug.current
  },
  "topicTheme": ${topicThemeQuery}
}`

export const presentationQuery = `{
  ...,
  slides[] {
    ...,
    image${IMAGE_FIELDS}
  }
}`

export const quizQuery = `{
  ...,
  questions[] {
    ...,
    media[0] {
      ...,
      _type == 'image' => ${IMAGE_FIELDS},
    },
  },
}`

export const articleQuery = `{
  ...,
  image${IMAGE_FIELDS},
  publishedAt,
  _updatedAt,
  loanArticle,
  loanArticleSource,
  "author": authors[0]->{
    title,
    role,
  },
  text[] {
    ${textQuery}
    _type == 'image' => ${IMAGE_FIELDS},
    _type == 'gallery' => {
      ...,
      images[]${IMAGE_FIELDS}
    }
  }
}`

export const videoQuery = groq`{
  ...,
  title,
  description,
  caption,
  videoType,
  publishedAt,
  _updatedAt,
  "slug": slug.current,
  "video": select(videoType == 'internal' => video{..., "videoFile": video{..., asset->}}, videoType == 'youtube' => id),
    'lessonRef': *[_type == 'lesson' && references(^._id)]{
    topic->{
      _type,
      title,
      'illustration': illustration.webp.asset->
    },
    title,
    _id,
    'slug': slug.current,
  }
}`

export const frontpageQuery = `{
  _id,
  content[] {
    ...,
    image${IMAGE_FIELDS},
    link {
      ...,
      reference->{
        _type,
        'slug': slug.current,
      },
    },
    lessons[]->{
      _id,
      title,
      purpose,
      duration,
      types,
      topic->{
        title,
        "slug": slug.current
      },
      "topicTheme": topic->${topicThemeQuery},
      'slug': slug.current,
    },
    _type == 'subTopicPromotion' => {
      title,
      preamble,
      "topicTheme": reference->topic->${topicThemeQuery},
      "image": reference->image${IMAGE_FIELDS},
      "slug": reference->slug.current,
    }
  },
  "topicThemes": *[_type == 'topic']${topicThemeQuery}
}`

export const aboutQuery = `{
  ...,
  image${IMAGE_FIELDS},
  text[]{
    ${textQuery}
  },
  employees[]->{
    ...,
    image${IMAGE_FIELDS}
  },
  previousEmployees[]->{
    ...,
    image${IMAGE_FIELDS}
  },
}`

export const resourcesQuery = groq`{
  ...,
  staticPagesData {
    ...,
    staticPages[]-> {
      _id,
      _type,
      publishedAt,
      "slug": slug.current,
      title,
      image${IMAGE_FIELDS},
      preamble
    },
  },
  articlesData {
    ...,
    articles[] {
      ...,
      image${IMAGE_FIELDS},
      _type == 'reference' => @-> {
        _id,
        _type,
        publishedAt,
        "slug": slug.current,
        title,
        image${IMAGE_FIELDS},
        loanArticle,
        loanArticleSource
      },
    },
  },
    videosData {
    ...,
     videos [] {
      ...,
      _type == 'reference' => @-> {
      ...
    }
    }
  },
  articles[] {
    ...,
    image${IMAGE_FIELDS},
    _type == 'reference' => @-> {
      _id,
      _type,
      publishedAt,
      "slug": slug.current,
      title,
      image${IMAGE_FIELDS},
      loanArticle,
      loanArticleSource
    },
  },
  externalArticlesData {
    ...,
    externalArticles[]{
      ...,
      file{
        asset->{
          extension,
          mimeType,
          size,
          url
        }
      },
      image${IMAGE_FIELDS}
    }
  },
  externalArticles[]{
    ...,
    file{
      asset->{
        extension,
        mimeType,
        size,
        url
      }
    },
    image${IMAGE_FIELDS}
  }
}`

export const talkingCardQuery = groq`{
  _id,
  title,
  digigen,
  backLink,
  "slug": slug.current,
  "topicTheme": topic->${topicThemeQuery},
  "cards": *[_type=='talkingCard' && references(^._id)] {
    _id,
    title,
    richText,
    type,
    textOrImage,
    image${IMAGE_FIELDS},
  },
  instructions->{...},
  logo${IMAGE_FIELDS},
  talkingCardTranslations->{...},
  language
}`

export async function getAllLessons() {
  return getClient().fetch(`
    *[_type == "lesson" && !(_id in path('drafts.**'))]${lessonQuery()}
  `)
}

export async function getAllPresentationsWithSlug() {
  return getClient().fetch(`
    *[_type == "presentation"] { 'slug': slug.current }
  `)
}

export async function getPresentation(slug, preview) {
  const curClient = getClient(preview)

  return curClient.fetch(
    `*[_type == "presentation" && slug.current == $slug]${presentationQuery}[0]`,
    { slug },
  )
}

export async function getAllQuizWithSlug() {
  return getClient().fetch(`
    *[_type == "quiz"] { 'slug': slug.current }
  `)
}

export async function getQuiz(slug, preview) {
  const curClient = getClient(preview)

  return curClient.fetch(`*[_type == "quiz" && slug.current == $slug]${quizQuery}[0]`, { slug })
}

export async function getAllArticlesWithSlug() {
  return getClient().fetch(`
    *[_type == "article"] { 'slug': slug.current }
  `)
}

export async function getArticle(slug, preview) {
  const curClient = getClient(preview)

  return curClient.fetch(`*[_type == "article" && slug.current == $slug]${articleQuery}[0]`, {
    slug,
  })
}

export async function getFrontpage() {
  return getClient().fetch(`*[_type == "frontpage"]${frontpageQuery}[0]`)
}

export async function getUrlList() {
  return getClient().fetch(`
  [
    {"url": ""},
    {"url": "om-oss"}
  ] + *[
      !(_id in path("drafts.**"))
      && _type in [
        'article',
        'presentation',
        'quiz',
        'topic',
        'lesson',
        'page'
      ]
      && defined(slug.current)
    ] {
      "url": select(
        _type == "lesson" => "undervisningsopplegg/",
        _type == 'article' => "artikkel/",
        _type == 'student' => "elevopplegg/",
        _type == 'quiz' => "quiz/",
        _type == 'presentation' => "presentasjon/",
        _type == 'topic' => "tema/",
        _type == 'page' => "",
        _id
      ) + slug.current,
      _updatedAt,
      _type,
      "priority": select(
        _type == "lesson" => "0.9",
        _type == 'article' => "0.8",
        _type == 'student' => "0.1",
        _type == 'quiz' => "0.7",
        _type == 'presentation' => "0.2",
        _type == 'topic' => "0.3",
        _type == 'page' => "0.8",
      )
    }`)
}

export async function getSettings(preview) {
  return getClient(preview).fetch(`// groq
    *[_id == "settings"][0]{
      menu[]{
        _key,
        title,
        "href": "/" + select(
          type == "internalRoute" => internalRoute,
          type == "dynamicPage" => dynamicPage->slug.current
        )
      },
      searchPlaceholders[],
      footer{
        ...,
      }
    }
  `)
}
